$theme-colors: (
	danger: rgb(220, 53, 69),
	dark: rgb(52, 58, 64),
	info: rgb(23, 138, 156),
	light: rgb(248, 249, 250),
	primary: rgb(0, 90, 138),
	success: rgb(10, 138, 40),
	warning: rgb(192, 90, 6),
);

$link-decoration: none;
$link-hover-decoration: none;
$enable-print-styles: false;

@import '~bootstrap/scss/bootstrap';
@import '~xterm/css/xterm.css';

html,
body {
	background-color: map-get($theme-colors, 'dark');
	height: 100%;
	width: 100%;
}

body {
	display: flex;
	flex-direction: column;

	> header {
		z-index: 4;

		> nav {
			background-color: map-get($theme-colors, 'light');
			display: flex;
			justify-content: space-between;
			padding: 0.46rem 0.96rem;

			> a {
				&:first-of-type {
					display: inline-flex;
					align-items: center;

					> strong {
						font-size: 1.46rem;
						margin-left: 0.23rem;
					}
				}
			}

			> section {
				> button {
					align-content: center;
					display: inline-flex;

					> svg {
						width: 1.32rem;
					}

					&[hidden] {
						+ span {
							display: none;
						}
					}
				}
			}
		}
	}

	> main {
		display: flex;
		flex-grow: 1;
		width: 100%;

		> aside {
			align-items: center;
			background-color: rgba(0, 0, 0, 0.32);
			display: flex;
			justify-content: center;
			margin-top: -1.5rem;
			position: fixed;
			top: 0;
			right: 0;
			bottom: 1.5rem;
			left: 0;
			z-index: 1;
		}
	}

	> footer {
		align-items: center;
		background-color: darken(map-get($theme-colors, 'dark'), 5);
		color: map-get($theme-colors, 'light');
		cursor: pointer;
		display: flex;
		font-size: small;
		justify-content: center;
		opacity: 0.69;
		padding-top: 0.23rem;
		padding-bottom: 0.1rem;
		z-index: 4;

		> q {
			&:empty {
				quotes: none;
			}
		}

		> cite {
			margin-left: 0.23rem;

			&:before {
				content: '— ';
			}
		}
	}
}

button,
input[type='submit'],
a[role='button'] {
	background-color: transparent;
	border: 0.23rem solid transparent;
	border-radius: 0.23rem;
	color: map-get($theme-colors, 'dark');
	display: inline-block;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	padding: 0.375rem 0.75rem;
	text-align: center;
	user-select: none;
	vertical-align: middle;
	transition: color 0.23s, background-color 0.23s, border-color 0.23s;

	&:not([disabled]) {
		cursor: pointer;
	}

	&[disabled] {
		cursor: not-allowed;
		opacity: 0.23;
	}

	&.block {
		width: 100%;
	}

	&.large {
		border-radius: 0.32rem;
		font-size: 1.23rem;
		line-height: 1.46;
		padding: 0.46rem 0.92rem;
	}

	&.small {
		border-radius: 0.23rem;
		font-size: 0.92rem;
		line-height: 1.46;
		padding: 0.23rem 0.46rem;
	}

	&:not([hidden]) {
		+ button,
		+ input[type='submit'],
		+ a[role='button'] {
			margin-left: 0.23rem;
		}
	}

	@each $name, $color in $theme-colors {
		&.#{$name} {
			@if $name == 'light' {
				color: black;
			} @else {
				color: white;
			}

			background-color: $color;
			border-color: $color;

			&.active,
			&:hover {
				background-color: darken($color, 3.2);
				@if $name == 'light' {
					border-color: darken($color, 3.2);
				}
			}

			&.outline {
				background-color: transparent;
				color: $color;

				&.active,
				&:hover {
					background-color: $color;

					@if $name == 'success' or $name == 'dark' or $name == 'primary' {
						color: white;
					} @else {
						color: black;
					}
				}
			}
		}
	}

	> svg {
		min-height: 0.69rem;
		min-width: 0.69rem;
		fill: currentColor;
	}
}

cs-application {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}

cs-landing {
	color: map-get($theme-colors, 'light');
	display: flex;
	flex-direction: column;
	height: 100%;
	text-align: center;

	> .d-flex {
		&:first-of-type {
			align-items: center;
			flex-grow: 1;
			justify-content: space-evenly;
			margin-top: 2.3rem;
			padding: 0 2.3rem;
			> h1 {
				font-weight: lighter;
			}
		}

		> dl {
			font-size: 1.69rem;
			margin-bottom: 9.2rem;

			> dt {
				border-bottom: 0.1rem solid transparentize(map-get($theme-colors, 'light'), 0.69);
				font-size: 1.23rem;
				font-weight: 300;
				margin-bottom: 1.23rem;
				padding-bottom: 0.23rem;
				text-transform: uppercase;
				text-align: left;
			}

			> dd {
				align-self: stretch;
				display: inline-flex;
				flex-direction: row;

				+ dd {
					margin-left: 3.2rem;
				}

				> svg {
					fill: currentColor;
					height: 2.3rem;
					width: 2.3rem;
					margin-right: 0.69rem;
				}
			}
		}

		> button {
			border-radius: 0;
			display: inline-flex;
			flex-basis: 33.3%;
			align-items: center;
			justify-content: center;
			margin: 0;
			min-height: 9.2rem;
			font-size: 2.3rem;
		}
	}
}

cs-project {
	height: 100%;
	position: relative;
}

cs-loginmodal {
	// needed for specificity override
	&[aria-modal='true'] {
		> form {
			> footer {
				grid-auto-columns: unset;

				> button:first-of-type {
					box-shadow: 0 0 0.32rem grey;
					display: inline-block;
					justify-self: start;
					margin-left: 0.1rem;

					&:hover {
						background-color: darken(white, 2.3%);
					}

					> :first-child {
						margin-right: 0.23rem;
					}
				}
			}
		}
	}
}

cs-editor,
cs-filesystem,
cs-htmlpreview {
	> aside {
		background-color: map-get($theme-colors, 'dark');
		box-shadow: 0.69rem -0.92rem 2.3rem rgba(0, 0, 0, 0.23);
		color: white;
		display: grid;
		grid-auto-flow: column;
		height: 3.63rem;
		padding: 0.23rem;

		> button {
			border-width: 0;

			&:hover {
				> svg {
					opacity: 1;
				}
			}

			> svg {
				height: 2rem;
				width: 2rem;
			}
		}

		label {
			margin-bottom: 0;
		}
	}
}

cs-filesystem {
	box-shadow: inset -0.23rem 0.23rem 0.69rem rgba(0, 0, 0, 0.23);
	color: white;
	display: flex;
	flex-direction: column;
	overflow: none;

	> aside {
		> button {
			border-width: 0.23rem;
		}
	}

	> section {
		padding: 0.32rem;
		padding-bottom: 0;
		margin-top: -0.92rem;
		margin-bottom: -0.05rem;

		> a {
			margin-top: 0.23rem;
		}
	}

	ul {
		cursor: pointer;
		font-size: 1rem;
		line-height: 1.92;
		margin-left: 1.69rem;
		padding: 0;
		position: relative;
		text-indent: 0.8rem;
		list-style: none;

		&:before {
			border-radius: 0.23rem;
			color: transparentize(silver, 0.32);
			content: '▹';
			font-size: 1.23rem;
			left: -1.46rem;
			line-height: 1.42;
			padding: 0 0.46rem;
			position: absolute;
			text-align: center;
			text-indent: 0;
		}

		&:hover {
			&:before {
				background-color: transparentize(map-get($theme-colors, 'primary'), 0.5);
			}
		}

		&.open {
			&:before {
				content: '▿';
			}

			> li {
				&:hover,
				&.selected {
					background-color: transparentize(map-get($theme-colors, 'primary'), 0.5);
				}
			}
		}

		&.closed {
			> ul,
			> li {
				display: none;
			}
		}

		> li {
			align-items: center;
			border-radius: 0.23rem;
			color: white;
			display: grid;
			font-size: 1rem;
			grid-auto-flow: column;
			grid-auto-columns: 2.3rem auto;
			margin: 0.23rem 0 0 0.23rem;
			word-break: break-all;
			transition: opacity 0.5s;

			&:before {
				color: silver;
				content: '⫏⫐';
				margin-left: -0.46rem;
				opacity: 0.23;
			}

			&:hover {
				> div {
					opacity: 1;
					background-color: transparentize(darken(map-get($theme-colors, 'primary'), 3.2), 0.1);
				}
			}

			&:last-of-type {
				margin-bottom: 0;
			}
			&:first-child {
				margin-top: 0.1rem;
			}

			> div {
				display: grid;
				grid-auto-flow: column;
				justify-self: end;
				opacity: 0;
				padding-right: 0.23rem;
				transition: background-color 232ms;

				> button {
					border-radius: 0;
					color: white;
					margin: 0 0 0 0.32rem;
					opacity: 0.23;
					padding: 0.23rem;
					transition: color 0.6s, opacity 0.5s;

					&:hover {
						opacity: 1;

						&[title='Copy File Path'] {
							color: map-get($theme-colors, 'info');
						}

						&[title='Rename File'] {
							color: map-get($theme-colors, 'warning');
						}

						&[title='Delete File'] {
							color: map-get($theme-colors, 'danger');
						}
					}

					> svg {
						min-width: 1.23rem;
					}
				}
			}
		}

		> ul {
			margin-top: 0.15rem;
		}
	}

	> ul {
		display: flex;
		flex-direction: column;
		font-size: 0;
		margin: 0;
		margin-top: 0.23rem;
		overflow: auto;
		padding: 0 0.23rem 0.92rem 0;

		&:before {
			display: none;
		}

		&::-webkit-scrollbar {
			background-color: transparent;

			&:horizontal {
				height: 0.32rem;
			}
			&:vertical {
				width: 0.32rem;
			}
		}

		&::-webkit-scrollbar-corner {
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background: silver;
			border-radius: 0.32rem;
		}

		> ul {
			margin-top: 0;
		}
	}
}

cs-editor {
	> aside {
		> button {
			&:last-of-type {
				margin-left: 0.46rem;
			}
		}
		> section {
			display: inline-flex;
			flex-direction: column;
			margin-left: 0.69rem;
		}
	}
	> #info {
		background-color: white;
		border-radius: 0.23rem;
		box-shadow: 0 0.32rem 1rem rgba(0, 0, 0, 0.32);
		padding: 0.69rem;
		position: absolute;
		top: 3.2rem;
		right: 0;
		z-index: 3;

		> button,
		> input[type='submit'],
		> a[role='button'] {
			border-width: 0.1rem;
		}

		> label {
			@extend .col-2;
			font-weight: bold;
			padding-left: 0.23rem;
		}

		> hr {
			+ label {
				flex: none;
				max-width: unset;
			}
		}

		> address {
			display: inline;
		}

		#project-mailto,
		#project-download {
			padding: 0.23rem 0.46rem;
			+ a {
				margin-right: 0.23rem;
			}
		}
	}

	> .monaco-editor {
		padding-top: 1.23rem;
	}
}

cs-htmlpreview {
	> iframe {
		background-color: white;
		height: calc(100% - 3.2rem);
		width: 100%;
	}
}

cs-pythonpreview {
	display: flex;
	width: unset !important;
	position: absolute;
	top: 3.2rem * 1.23;
	right: 1.23rem;
	z-index: 2;

	> :first-child {
		order: 2;

		&:empty {
			+ * {
				visibility: hidden;
			}
		}
	}

	> :last-child {
		font-size: 3.2rem;
		order: 1;
		padding: 0 0.32rem 0.69rem 0.69rem;
	}

	> button {
		font-size: 1.69rem;
		height: 2.69rem;
		line-height: 0;
		vertical-align: middle;
	}
}

cs-terminal {
	background-color: black;
	border: 0.23rem solid rgba(232, 232, 232, 0.23);
	border-radius: 0.23rem;
	box-shadow: 0 0 2.3rem rgba(0, 0, 0, 0.32);
	bottom: 0;
	left: 0;
	right: 0;
	position: absolute;
	z-index: 1;

	.xterm-screen,
	.xterm-viewport {
		width: 100% !important;
	}

	.xterm-screen {
		background-color: black;
		padding: 0.32rem;
	}
}

cs-notifier {
	position: fixed;
	top: 3.46rem;
	right: 0.23rem;
	width: 23rem;
	z-index: 4;

	> cs-notification {
		align-items: center;
		border-radius: 0.23rem;
		box-shadow: 0 0.23rem 0.69rem rgba(0, 0, 0, 0.23);
		cursor: pointer;
		color: white;
		display: flex;
		opacity: 0;

		&:not(:last-child) {
			margin-bottom: 0.23rem;
		}

		> header {
			background-color: rgba(255, 255, 255, 0.85);
			border-bottom-left-radius: 0.23rem;
			border-top-left-radius: 0.23rem;
			color: map-get($theme-colors, 'dark');
			margin-left: -0.23rem;
			padding: 0.23rem 0.69rem;
		}

		> main {
			flex-basis: 0;
			flex-grow: 1;
			font-size: 1.23rem;
			font-weight: bold;
			padding: 0.69rem;
		}

		@each $name, $color in $theme-colors {
			&.#{$name} {
				background-color: $color;

				@if $name == 'light' {
					color: black;

					> header {
						background-color: map-get($theme-colors, 'dark');
						color: white;
					}
				}
			}
		}
	}
}

[aria-modal='true'] {
	border-radius: 0.23rem;
	height: 100%;
	opacity: 0;
	overflow: hidden;
	position: fixed;
	transition: opacity 0.23s;
	top: 3.2rem;
	right: 0;
	left: 0;
	z-index: 4;

	> form {
		@media (min-width: 576px) {
			max-width: 32rem;
			margin: 1rem auto;
		}

		background-color: white;
		color: black;
		margin: 0.5rem;
		position: relative;
		width: auto;

		> header {
			border-bottom: 0.23rem solid map-get($theme-colors, 'light');
			padding: 0.69rem 0.23rem;
			text-align: center;

			> h5 {
				font-size: 1.46rem;
				margin-bottom: 0;
				width: 100%;
			}
		}

		> main {
			flex: 1 1 auto;
			padding: 0.69rem;
			margin: 0.32rem 0;
			position: relative;
		}

		> footer {
			border-top: 0.23rem solid map-get($theme-colors, 'light');
			display: grid;
			grid-auto-flow: column;
			grid-auto-columns: 50%;
			justify-content: unset;
			padding: 0.69rem;
		}
	}
}

.backdrop {
	background-color: rgba(0, 0, 0, 0.23);
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	transition: opacity 0.22s;
	z-index: 3;
	width: 100vw;
	height: 100vh;
}

.input-group {
	> .input-group-append,
	> .input-group-prepend {
		> button,
		> input[type='submit'],
		> a[role='button'] {
			border-width: 0.1rem;
		}
	}

	> .input-group-prepend {
		> button,
		> input[type='submit'],
		> a[role='button'] {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			border-right: 0;
		}
	}

	> .input-group-append {
		> button,
		> input[type='submit'],
		> a[role='button'] {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			border-left: 0;
		}
	}
}
